@import "./_mixins";

@mixin mobile-footer-styles {
  @include respond-to("sm", true) {
    .footer {
      padding: 0px;
      .footer-container {
        width: 100%;

        > div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 14px 24px;

          .footer-brand {
            flex-direction: column;
            display: flex;
            align-items: start;
            justify-content: start;
            gap: 4px;

            h2 {
              font-size: 13px;
              font-weight: 700;
              line-height: 13px;
            }

            img {
              width: 23px;
              height: 23px;
            }
          }

          .footer-links {
            position: static;

            > div {
              .activeNavLink {
                text-decoration: none;
              }
            }

            a {
              font-size: 12px;
              font-weight: 700;
              line-height: 24px;
            }
          }
        }
      }
    }

    .contact-footer {
      height: 100%;
      .contact-footer-container {
        width: 100%;
        height: 100%;
        .grid-container {
          width: 100%;
          flex-direction: column;
          align-items: start;
          gap: 13px;
          padding: 15px 0px 14px 32px;

          .contact-details {
            width: 100%;
            font-size: 12px;
            display: flex;
            align-items: start;
            flex-direction: column;
            justify-content: space-around;
            gap: 10px;

            > div {
              gap: 14px;
              .messageicon {
                margin-right: 4px;
              }
              img {
                margin-right: 4px;
              }

              h4,
              h5 {
                font-size: 12px;
              }
            }
          }

          .copyright {
            margin-top: 5px;
            font-size: 12px;
            font-weight: 400;
            opacity: 50%;
          }
        }
      }
    }
  }
}
