@import "../styles/index.scss";
@import "./mixins/mobileAppbar.scss";
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@400;500;700&family=Roboto:wght@500&display=swap");

.appbar {
  background: linear-gradient(180deg, #000000 4.17%, rgba(0, 0, 0, 0) 100%);
  height: 226px;
  width: 100vw;
  display: flex;
  align-items: start;
  justify-content: center;

  .appbar-container {
    width: 75%;
    padding-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    .MuiToolbar-root {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 100%;
    }
    .logo-container {
      width: 201px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .brand-logo {
        width: 56px;
        top: 45px;
        height: 56px;
        border-radius: 100%;
      }
      h3 {
        // width: 133px;
        font-size: 28px;
        line-height: 32px;
        color: #ffffff;
        font-family: "Lexend", sans-serif;
        font-weight: 400;
        margin-left: 5px;

        canvas {
          margin-top: -1rem;
        }
      }
    }
    .menuicon {
      font-size: 35px;
      color: white;
      // margin-left: 50px;
    }

    .language-select {
      // margin-top: 65px;
      width: 220px;
      height: 48px;
      background: linear-gradient(#f2f2f289, #f2f2f250);
      border-radius: 10px;
      backdrop-filter: blur(5px);
      div {
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
          width: 36px;
          height: 24px;
          border: 1px solid #d1d6e9;
          border-radius: 4px;
        }
        p {
          width: 68px;
          font-size: 14px;
          line-height: 20px;
          margin-left: 10px;
        }
      }
    }

    .pagesBtnCon {
      position: relative;
      margin-right: 25px;
      gap: 20px;
      justify-content: flex-end;
      flex-grow: 1;

      div {
        position: relative;
        a {
          color: white;
          text-decoration: none;
          // background-color: red !important;
        }

        a::after {
          content: "";
          position: absolute;
          width: 0;
          height: 2px; /* Adjust the height of the underline */
          bottom: -5px;
          left: 0;
          background-color: white; /* Replace with your desired color */
          transition: width 0.2s ease-in-out; /* Adjust the duration and timing function as needed */
        }

        a:hover::after {
          width: 100%;
        }

        .activeNavLink {
          text-decoration: underline;
          text-underline-offset: 5px;
        }
      }
    }
  }
  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  // Calling MIXINS (scss function) TO IMPLEMENT MOBILE STYLES
  @include mobile-appBar-styles;
}

.nonHomeAppBar {
  /* Header Background */
  background: linear-gradient(180deg, #1b3474 0%, #176ec6 100%);
  width: 100vw;
  padding-top: 54px;
  padding-bottom: 20px;
  display: flex;
  align-items: start;
  justify-content: center;

  .appbar-container {
    width: 75%;
    display: flex;
    align-items: center;
    justify-content: center;

    .MuiToolbar-root {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 100%;
    }
    .logo-container {
      width: 201px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .brand-logo {
        width: 56px;
        top: 45px;
        height: 56px;
        border-radius: 100%;
      }
      h3 {
        // width: 133px;
        font-size: 28px;
        line-height: 32px;
        color: #ffffff;
        font-family: "Lexend", sans-serif;
        font-weight: 400;
        margin-left: 5px;

        canvas {
          margin-top: -1rem;
        }
      }
    }
    .menuicon {
      font-size: 35px;
      color: white;
      // margin-left: 50px;
    }
    .pagesBtnCon {
      position: relative;
      margin-right: 25px;
      gap: 20px;
      justify-content: flex-end;
      flex-grow: 1;

      div {
        position: relative;
        a {
          color: white;
          text-decoration: none;
        }

        .activeNavLink {
          text-decoration: underline;
          text-underline-offset: 7px;
        }

        a::after {
          content: "";
          position: absolute;
          width: 0;
          height: 2px; /* Adjust the height of the underline */
          bottom: -5px;
          left: 0;
          background-color: white; /* Replace with your desired color */
          transition: width 0.2s ease-in-out; /* Adjust the duration and timing function as needed */
        }

        a:hover::after {
          width: 100%;
        }
      }
    }

    .language-select {
      // margin-top: 65px;
      width: 220px;
      height: 48px;
      background: linear-gradient(#f2f2f289, #f2f2f250);
      border-radius: 10px;
      backdrop-filter: blur(5px);
      div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
          width: 68px;
          font-size: 14px;
          line-height: 20px;
          margin-left: 10px;
          img {
            width: 36px;
            height: 24px;
            border: 1px solid #d1d6e9;
            border-radius: 4px;
          }
        }
      }
    }
  }
  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  // Calling MIXINS (scss function) TO IMPLEMENT MOBILE STYLES
  @include mobile-appBar-styles;
}

a {
  text-decoration: none;
  color: black;
}

.drop-language-select {
  img {
    height: 1.2rem;
    aspect-ratio: 3 / 2;
    outline: 1px solid #00000036;
  }
}

.MuiDrawer-root {
  .MuiDrawer-paper {
    width: 70%;
    display: flex;
    align-items: start;
    flex-direction: column;
    gap: 10px;
    padding: 10px 12px;

    .drawer-header-container {
      display: flex;
      align-items: start;
      width: 100%;
      justify-content: space-between;
    }

    .logo-container {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      padding-bottom: 24px;
      .brand-logo {
        height: 36px;
        width: 36px;
        border-radius: 100%;
      }
      h3 {
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        color: #00339b;
      }
    }

    .mobile-menu-item {
      width: 100%;
      background-color: #f4f6fe;
      border-radius: 8px;

      li {
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
      }
    }
  }
}
