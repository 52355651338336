@import "../styles/index.scss";
@import "./mixins/mobileFooter.scss";

.footer {
  background-color: $blue-bg;
  padding: 1.5rem 0rem 2rem 0rem;
  margin-top: 125px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;

  .footer-container {
    width: 75%;
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
    }
    .footer-brand {
      width: 145px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .footer-brand-logo {
        width: 43px;
        height: 42px;
        border-radius: 100%;
      }

      h2 {
        width: 97px;
        font-size: 21px;
        font-weight: 400;
        color: white;
        font-family: "Lexend", sans-serif;

        canvas {
          margin-top: -1rem;
        }
      }
    }

    .footer-links {
      display: flex;
      gap: 34px;
      width: fit-content;
      position: absolute;
      top: 20%;
      left: 44%;

      div {
        position: relative;
        a {
          color: white;
          font: 16px;
          font-weight: 500;
        }

        .activeNavLink {
          text-decoration: underline;
          text-underline-offset: 7px;
        }

        a::after {
          content: "";
          position: absolute;
          width: 0;
          height: 2px; /* Adjust the height of the underline */
          bottom: -5px;
          left: 0;
          background-color: white; /* Replace with your desired color */
          transition: width 0.2s ease-in-out; /* Adjust the duration and timing function as needed */
        }

        a:hover::after {
          width: 100%;
        }
      }
    }
  }
}

.contact-footer {
  width: 100vw;
  font: 14px;
  font-weight: 400;
  background-color: $blue-bg-dark;
  color: white;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;

  .contact-footer-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100vw;

    .grid-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 75%;
    }

    .contact-details {
      display: flex;
      align-items: center;
      gap: 24px;
      font-size: 14px;
      font-weight: 400;

      > div {
        display: flex;
        align-items: center;
        gap: 32px;
      }

      img {
        width: 14px;
        height: 14px;
        margin-right: 9px;
      }

      .messageicon {
        margin-right: 9px;
      }

      h4 {
        display: flex;
        align-items: center;
      }

      h5 {
        display: flex;
        align-items: center;

        canvas {
          margin-top: -50%;
        }
      }

      a {
        color: white;
        font: 14px;
        font-weight: 400;
        font-size: 14px;
        font-weight: 400;
      }
    }

    .copyright {
      display: flex;
      align-items: center;
      justify-content: end;
    }
  }
}

@include mobile-footer-styles;
