.notFoundContainer {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100svh;

  .notFound {
    height: 40vh;
    display: grid;
    place-items: center;
    margin: auto;

    width: 60%;

    h1 {
      font-size: 3em;
      margin-bottom: 20px;
    }

    p {
      font-size: 1.2em;
    }
  }

  .footercontainer {
    margin-top: auto;
    width: 100%;
    text-align: left;
  }

  .appbar {
    background: rgba(58, 21, 27, 1);
    height: 10rem;
  }
}
