@import "../index.scss";

.searchBar {
  padding: 37px;
  margin-top: 10px;
  background-color: white;
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 16px;

  .searchButton,
  .searchSelect {
    height: 48px;
    border-radius: 8px;
  }

  .MuiButtonBase-root {
    width: 200px;
  }

  .searchButton {
    background-color: $blue-bg;
    font-size: 18px;
  }
}
