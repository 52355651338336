.fallbackPageLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;

  .animatedCarLoader {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
