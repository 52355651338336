html {
  overflow-x: hidden;
}

body {
  padding-right: 0px !important;
}

// theme variables
$blue-bg: #226db8;
$blue-bg-dark: #092947;

$primary-bg: white;
$secondary-bg: #ebf0ff;

$text-primary: #000000;
$text-secondary: #34364d;
$text-sub-headings: #4b4f67;
$text-heading-secondary: #226db8;

// breakpoints
$breakpoints: (
  // 'xs': Extra small devices (phones, portrait mode) - up to 320px
  "xs": 320px,
  // 'sm': Small devices (phones, landscape mode) - up to 576px
  "sm": 576px,
  // 'md': Medium devices (tablets) - up to 768px
  "md": 768px,
  // 'lg': Large devices (desktops, laptops) - up to 992px
  "lg": 992px,
  // 'xl': Extra large devices (large desktops) - up to 1200px
  "xl": 1200px
);
