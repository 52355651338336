@import "../index.scss";

// responsiveness mixins
// Define mixin
@mixin respond-to($breakpoint, $isMax: false) {
  $width: map-get($breakpoints, $breakpoint);
  @if map-has-key($breakpoints, $breakpoint) {
    @if $isMax {
      @media (max-width: #{ $width - 1px }) {
        @content;
      }
    } @else {
      @media (min-width: $width) {
        @content;
      }
    }
  } @else {
    @warn "Breakpoint not found in map: #{$breakpoint}";
  }
}
