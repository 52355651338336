@import "../mixins/_mixins.scss";

@mixin mobile-homepage-styles {
  // MOBILE VIEW STYLES
  @include respond-to("sm", true) {
    .homepage {
      //   border: 2px solid red;
      display: flex;
      flex-direction: column;
      width: 100%;
      overflow-x: hidden;
      margin: 0px;
      padding: 0px;

      //   ONLY TOP HERO SECTION COMPONENTS
      .home {
        // border: 2px solid yellow;
        padding-bottom: 8%;

        .home-bannerImage {
          height: 720px;
        }

        .card-search-container {
          //   border: 2px solid purple;
          display: flex;
          flex-direction: column;
          gap: 0px;
          align-items: center;

          //   TRANSLUCENT CARD SECTION
          .topheader-container {
            width: 22rem;
            display: flex;
            flex-direction: column;
            align-items: start;
            padding: 25px 25px 0px 25px;
            gap: 20px;

            .header-container {
              width: 100%;
              p {
                font-size: 14px;
                font-weight: 400;
              }

              h2 {
                font-weight: 700;
                font-size: 32px;
                color: #1c1c21;
              }
            }

            .feature-container {
              padding-bottom: 30px;
            }
          }

          // SEARCH BAR
          .searchBar {
            width: 20rem;
            padding: 24px 10px 24px 10px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .MuiFormControl-root {
              width: 300px;
              margin: 0px;
            }

            .searchButton {
              width: 93%;
            }
          }
        }
      }

      // PROCESS COMP
      .process-container {
        background-color: #eaeffe;
        height: 133px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-bottom: 0px;

        .process-sub-container {
          position: absolute;
          top: 20%;

          .wrapper {
            .process-content {
              img {
                width: 32px;
                height: 32px;
              }
              p {
                font-size: 12px;
                font-weight: 300;
              }
            }

            .process-arrow {
              img {
                width: 12px;
                height: 12px;
              }
            }
          }
        }

        .backgroundImage {
          display: none;
        }
      }

      // PLACES COMP
      .country-container {
        margin-top: 30px;
        // border: 2px solid red;
        display: flex;
        flex-direction: column;
        align-items: center;

        .MuiContainer-root {
          //   border: 2px solid red;
          padding: 0px;

          .card-container-header {
            // border: 2px solid blue;
            width: 20rem;
            display: flex;
            align-items: center;

            h1 {
              //   border: 2px solid red;
              width: 100%;
              font-size: 28px;
              line-height: 34px;
              //   text-align: justify;
            }

            p {
              // border: 2px solid red;
              width: 100%;
              line-height: 22px;
              text-align: justify;
            }
          }
        }

        .card-container {
          display: flex;
          flex-direction: column;
          align-items: start;
          justify-content: center;
          //   border: 2px solid red;
          //   position: relative;

          .card-grid-container {
            // border: 2px solid red;
            display: grid;
            grid-template-columns: 50% 50%;
            width: fit-content;
            gap: 18px;
            padding: 0px 20px 0 20px;

            .card {
              padding: 8px 8px 15px 8px;
              width: 150px;
              height: 185px;
              img {
                width: 100%;
                object-fit: cover;
              }
            }
          }
        }
      }

      // EUROPE MAO
      .europeMap {
        border: 2px solid red;
        display: none;
      }

      // Define component
      .define-container {
        width: 90%;
        text-align: start;
        margin: 40px auto 32px auto;

        h1 {
          font-size: 25px;
          line-height: 44px;
        }

        p {
          font-size: 14px;
          line-height: 20px;
        }
      }

      // Reviews COMP
      .review-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        h1 {
          font-size: 28px;
          line-height: 46px;
        }

        .review-card {
          height: 200px;
          width: 22rem;
          display: flex;
          flex-direction: column;

          .review-content {
            font-size: 14px !important;
            line-height: 24px !important;
            font-weight: 600 !important;
          }
        }
      }
    }

    .accept-cookie {
      > div {
        display: flex;
        flex-direction: column;
        padding: 30px;
        gap: 30px;

        .accept-description {
          text-align: justify;
        }

        .buttonGroup {
          display: flex;
          align-items: center;
          justify-content: start;
          flex-direction: row;
          gap: 40px;
          padding: 0px;
          margin: 0px;
        }
      }
    }

    .appbar {
      height: 130px !important;
    }
  }
}
