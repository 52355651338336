@import "./_mixins";

@mixin mobile-appBar-styles {
  // MOBILE VIEW STYLES
  @include respond-to("sm", true) {
    width: 100vw;
    padding: 0px;

    .appbar-container {
      padding: 0px;
      width: 100%;

      .MuiToolbar-root {
        padding: 20px 24px 20px 24px;
        display: flex;
        flex-direction: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        .logo-container {
          justify-content: start;
          width: fit-content;
          gap: 12px;
          .brand-logo {
            height: 36px;
            width: 36px;
          }
          h3 {
            font-size: 16px;
            font-weight: 700;
          }
        }

        .mobilemenucon {
          display: flex;
          align-items: center;
          justify-content: end;

          .mobile-form-control {
            height: 32px;
            width: 111px;
            position: relative;

            .language-select {
              width: 7rem;
              height: 32px;
              border-radius: 8px;

              div {
                padding: 5px;
                justify-content: start;
                img {
                  width: 30px;
                  height: 20px;
                }
                p {
                  font-size: 14px;
                  width: 100%;
                  font-weight: 700;
                  line-height: 20px;
                  color: white;
                  text-align: left;
                }
              }
            }

            svg {
              width: 25px;
              height: 25px;
              top: 10%;
            }
          }

          .menuicon {
            width: 32px;
            height: 32px;
          }
        }
      }
    }
  }

  // REMOVES LANGUAGE SELECT ON SCREENS BIGGER THAN SM
  @include respond-to("sm") {
    .mobile-form-control {
      display: none;
    }

    .mobilemenucon {
      display: none;
    }
  }
}
