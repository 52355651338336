@import "../index.scss";
@import "../mixins/mobileHomePage.scss";
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@400;500;700&family=Roboto:wght@500&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Lexend", sans-serif;
  scroll-behavior: smooth;
}

.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  padding-bottom: 45px;

  .home-bannerImage {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -9999;
    width: 100%;
    height: 632px;
  }

  .topheader-container {
    width: 75vw;
    background: linear-gradient(
      90.12deg,
      rgba(255, 255, 255, 0.6) 33.36%,
      rgba(239, 242, 249, 0.06) 99.89%
    );
    backdrop-filter: blur(10px);
    border-radius: 20px;
    padding: 40px;

    .header-container {
      h2 {
        width: 100%;
        font-weight: 700;
        color: $text-primary;
        font-size: 56px;
        padding-top: 0.5rem;
        font-family: "Lexend", sans-serif;
        text-transform: uppercase;

        span {
          color: #1c1c21;
          font-size: 40px;
        }
      }

      p {
        width: 756px;
        font-size: 16px;
        font-weight: 400;
        color: $text-secondary;
        line-height: 22px;
        font-family: "Lexend", sans-serif;
      }
    }

    .feature-container {
      h3 {
        color: $text-secondary;
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
        font-family: "Lexend", sans-serif;
      }

      p {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        font-family: "Lexend", sans-serif;
        color: $text-secondary;
        margin-left: 30px;
      }

      .feature-sub-container {
        display: flex;
        align-items: center;
        gap: 8px;

        img {
          margin-right: 5px;
        }
      }

      .feature-sub-container1 {
        display: flex;
        align-items: center;
        gap: 8px;

        img {
          margin-right: 5px;
        }
      }
    }
  }
}

.shoppingCartIcon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.define-container {
  background-color: $secondary-bg;
  padding: 1rem 3rem 2rem 3rem;
  border-radius: 8px;
  margin: 80px 0 80px 0;

  h1 {
    font-weight: 700;
    font-size: 32px;
    line-height: 56px;
    text-align: center;
    padding: 2.5rem 0rem 1.5rem 0rem;

    span {
      color: $text-heading-secondary;
    }
  }

  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    color: $text-secondary;
  }
}

.btm-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;

  .btm-btn {
    background-color: $blue-bg;
    padding: 12px, 36px, 12px, 36px;
    width: 253px;
    height: 48px;
    border-radius: 12px;
    gap: 10px;
    box-shadow: none;
    font-size: 18px;
    font-weight: 400;
  }

  canvas {
    margin-top: -10px;
  }
}

.optionsDiv {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 12px;
  img {
    height: 1.5rem;
  }
  .flagimg {
    height: 1.5rem;
  }
}

.placeholderOption {
  margin: 1px 10px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 3px 5px;
  > p {
    font-size: 0.9rem;
  }
}

.accept-cookie {
  width: 100dvw;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: $secondary-bg !important;
  z-index: 1000;
  box-shadow: 0 10px 20px $text-secondary;
  strong {
    text-decoration: underline;
  }
  > div {
    padding: 3rem;
    width: fit-content;
    margin-inline: auto;
    display: flex;
    color: $text-secondary;
    gap: 1rem;
    div {
      max-width: 1000px;
    }
    > div:last-child {
      min-width: 280px;
      > button {
        margin-inline: 10px;
      }
      > button:first-child {
        background: $blue-bg;
      }

      > button:last-child {
        color: #226db8;
        background: transparent;
        border: 1px solid #226db8;
      }
    }
  }
}

@include mobile-homepage-styles;
